import {
	$dqs,
	$dqsa,
	getCarouselEmbedEle,
	getSettingsStyle,
	getStars,
	getSvg,
	v,
} from "../swiper-reviews";

export async function setPrimaryCarousel(settings, index) {
	if ($dqs('#tt-carousel[data-type="primary"]')) {
		return;
	}
	let maxWidth = "initial";
	const { swiperStyle } = v;
	const column = v.columns[index];

	const target = getCarouselEmbedEle(
		"#trustoo-card-carousel-widget",
		settings.is_home_active,
		settings.is_product_active
	);
	if (!target) {
		return;
	}

	const activationVal = 1,
		all = 1,
		feature = 2,
		productReviews = 5;
	const params = {};
	if (settings.show_reviews === all) {
		params.is_show_all = activationVal;
	} else if (settings.show_reviews === feature) {
		params.is_feature = activationVal;
	} else if (settings.show_reviews === productReviews) {
		if (v.productId) {
			params.product_id = v.productId;
		} else {
			params.is_show_all = activationVal;
		}
	}
	const revRed = await v.request("getReviews", params);
	let reviewsList = null,
		ratingData = null;
	if (revRed[0]) {
		return;
	} else {
		if (revRed[1].list.length === 0) {
			return;
		}
		reviewsList = revRed[1].list;
		ratingData = revRed[1].total_rating;
	}
	v.reviewsLists[index] = reviewsList;
	const cardCount = reviewsList.length;
	if (cardCount < v.columns[index]) {
		v.columns[index] = cardCount;
	}
	let headerHtml = "",
		extraCls = "";
	const s = settings;
	swiperStyle.textContent += `
		#tt-carousel[data-type=primary]{
			--card-radius: initial;
			--header-card-bd:${s.header_card_bg_color};
			--header-star-color:${s.header_star_color};
			--header-nostar-color:${s.header_disabled_star_color};
			--header-text:${s.header_text_color};
			--badge-text-color:${s.widget_layout_badge_text_color};
		}
		.tt-swiper .tt-swiper-header .vstar-star{
			--header-star-color:${s.header_star_color};
			--header-nostar-color:${s.header_disabled_star_color};
		}
		${getSettingsStyle(s, "primary")}
		`;
	const words = v.lang;
	if (settings.is_show_header === 1) {
		headerHtml = `<div class="tt-swiper-header">
      <div class="swiper-header-title"></div>
      <div class="swiper-header-stars vstar-star"></div>
      <div class="swiper-header-rating"></div>
      <div class="swiper-header-count"></div>
    </div>`;
	} else {
		extraCls = "no-header";
	}
	const btnColor = settings.scroll_buttons_icon_color;

	// if (!v.isProPage) {
	// 	opacity = 1;
	// }
	if (
		(settings.is_home_active === 1 && v.isHomePage) ||
		(settings.is_product_active === 1 && v.isProPage)
	) {
		maxWidth = "1200px";
	}
	//  else if (settings.is_product_active === 1 && v.isProPage) {
	// 	if (typeof Review !== "undefined") {
	// 		const reviews = $dqs("#vstar-reviews");
	// 		if (reviews) {
	// 			maxWidth = getComputedStyle(reviews).width;
	// 			opacity = 1;
	// 		}
	// 		if (Review.hasReviews === false) {
	// 			maxWidth = "1200px";
	// 			opacity = 1;
	// 		}
	// 	} else {
	// 		maxWidth = "1200px";
	// 		opacity = 1;
	// 	}
	// }

	let html = `
    <div id="tt-carousel" data-type="primary" class="tt-swiper  column-${column} pending ${
		reviewsList.length <= column ? "hide-btn" : ""
	}" style="max-width:${maxWidth}">
      ${headerHtml}
    </div>
  `;
	target.insertAdjacentHTML("beforeend", html);

	const header = $dqs(".tt-swiper-header");

	const headWords = words.card_carousel;
	$dqsa(".tt-review-verify").forEach(
		i => (i.textContent = words.verified_purchase)
	);
	if (settings.is_show_header === 1) {
		$dqs(".swiper-header-title", header).textContent = headWords.header_title;
		$dqs(".swiper-header-rating", header).textContent +=
			" " + headWords.header_review_rating_text;
		$dqs(".swiper-header-count", header).textContent +=
			" " + headWords.header_review_count_text;
	}
	$dqsa(".tt-card .tt-review-verify").forEach(
		i => (i.textContent = words.verified_purchase)
	);
	if (settings.is_show_header === 1) {
		const numberContent = ratingData.total_reviews.toLocaleString();

		const ratingNode = $dqs(".tt-swiper-header .swiper-header-rating");
		const countNode = $dqs(".tt-swiper-header .swiper-header-count");
		$dqs(".swiper-header-stars", header).innerHTML = getStars(
			ratingData.rating,
			v
		);
		ratingNode.textContent = ratingData.rating + ratingNode.textContent;
		countNode.textContent = numberContent + countNode.textContent;
	}

	if (reviewsList.length === 0) {
		return;
	}

	const wrapper = document.querySelector("#tt-carousel[data-type=primary]");
	wrapper.dataset.rating = ratingData.rating;
	wrapper.dataset.count = ratingData.total_reviews;
	wrapper.insertAdjacentHTML(
		"beforeend",
		`<div class="tt-swiper-wrapper ${extraCls}">
			<div id="tt-carousel-content">
				<div id="tt-carousel-list" data-index="${index}" class="tt-card-list">
				<div class="tt-swiper-container swiper-wrapper"
					data-index="${index}"
					data-count="${cardCount}"
					navigation="false"
					space-between="12"
					loop="true"
					slides-per-view="${column}"
					${v.isSetTimer ? `autoplay autoplay-pause-on-mouse-enter="true"` : ""}
					speed="300"
				>
				${getPrimaryList(reviewsList, settings)}
			</div>
				</div>
			</div>
			<div class="tt-swiper-pre tt-swiper-btn">${getSvg("scrollBtn", btnColor)}</div>
			<div class="tt-swiper-next tt-swiper-btn">${getSvg("scrollBtn", btnColor)}</div>
		</div>`
	);

	if (reviewsList.length <= column) {
		wrapper.classList.add("hide-btn");
	}

	try {
		sessionStorage.setItem(
			"trustoo_swiper_reviews",
			JSON.stringify(reviewsList)
		);
	} catch (error) {}

	$dqsa(".tt-card .tt-review-verify").forEach(
		i => (i.textContent = words.verified_purchase)
	);
}

export function getPrimaryList(reviewsList, settings) {
	let reviewsHtml = "";
	const words = v.lang;
	let verifyText = words.verified_purchase ? words.verified_purchase : "";
	reviewsList.forEach((i, inx) => {
		let verifiedHtml = "",
			verifiedContent = "",
			reviewDate = "",
			reviewsCountry = "";
		if (settings.is_show_verified_purchase === 1 && i.verified_badge === 1) {
			verifiedContent = `${v.badgeIconSvg}<span class="tt-review-verify">${verifyText}</span>`;
		}
		verifiedHtml = `<div class="card-head-row2">${verifiedContent}</div>`;

		const timeAgoType = 5;
		const dateType = settings.review_date_format_type;
		if (dateType === timeAgoType) {
			reviewDate = getTimeAgo(i.commented_at);
		} else {
			reviewDate = dateFormat(i.commented_at, dateType);
		}

		if (settings.is_show_country_flag === 1) {
			reviewsCountry = i.author_country;
		}

		let imagesHtml = "";
		i.resources.forEach((it, index) => {
			let sty = "",
				wh = "";
			if (it.width < it.height) {
				sty = "width:100%;";
				const scale = (
					parseFloat((it.height / it.width).toFixed(2)) * 44
				).toFixed(2);
				wh = `width="44px" height="${scale}px"`;
			} else {
				const scale = (
					parseFloat((it.width / it.height).toFixed(2)) * 44
				).toFixed(2);
				sty = "height:100%;";
				wh = `width="${scale}px" height="44px"`;
			}
			if (index !== 0) {
				sty += "display:none";
			}
			imagesHtml += `<div class="resource-item tt-reviews-image">
				<img index="${index}" ${wh} alt="${index}" style="${sty}"
				 data-original="${it.thumb_src}" src="${it.thumb_src}" >
				</div>`;
		});
		imagesHtml = `<div class="resource-list">${imagesHtml}</div>`;
		reviewsHtml += `
		<div class="swiper-slide">
    <div class="tt-card-wrapper">
      <div class="tt-card" no="${inx}">
        <div class="tt-card-head">
          <div class="card-head-row1">
            <div class="tt-review-name">${i.author}</div>
            <div class="tt-review-star vstar-star">${getStars(i.star, v)}</div>
          </div>
            ${verifiedHtml}
        </div>
        <div class="tt-card-body">
            ${i.content}
        </div>
        <div class="tt-card-footer">
				${imagesHtml}
          
          <div class="tt-card-info">
            <span class="tt-review-country">${reviewsCountry}</span>
            <span class="tt-review-date">${reviewDate}</span>
          </div>
        </div>
      </div>
    </div></div>
    `;
	});
	return reviewsHtml;
}

function getTimeAgo(timeString) {
	// 将时间字符串转换为 Date 对象
	const date = new Date(timeString + "Z");
	const timestamp = date.getTime(); // 获取毫秒时间戳

	const now = new Date().getTime();
	const diffInSeconds = Math.floor((now - timestamp) / 1000); // 时间差（秒）

	const secondsInMinute = 60;
	const secondsInHour = 60 * secondsInMinute;
	const secondsInDay = 24 * secondsInHour;
	const secondsInMonth = 30 * secondsInDay;
	const secondsInYear = 365 * secondsInDay;
	const texts = v.lang.time_ago;
	let number = "";
	// 判断时间差范围并返回对应的字符串
	if (diffInSeconds < secondsInMinute) {
		return texts.just;
	} else if (diffInSeconds < secondsInHour) {
		number = Math.floor(diffInSeconds / secondsInMinute);
		return texts.minutes.replace("{{time_number}}", number);
	} else if (diffInSeconds < secondsInDay) {
		number = Math.floor(diffInSeconds / secondsInHour);
		return texts.hours.replace("{{time_number}}", number);
	} else if (diffInSeconds < secondsInMonth) {
		number = Math.floor(diffInSeconds / secondsInDay);
		return texts.days.replace("{{time_number}}", number);
	} else if (diffInSeconds < secondsInYear) {
		number = Math.floor(diffInSeconds / secondsInMonth);
		return texts.months.replace("{{time_number}}", number);
	} else {
		number = Math.floor(diffInSeconds / secondsInYear);
		return texts.years.replace("{{time_number}}", number);
	}
}

function dateFormat(dateTime, type) {
	const date = dateTime.split(" ")[0];
	let d = [];
	if (date.indexOf("/") !== -1) {
		d = date.split("/");
	} else if (date.indexOf("-") !== -1) {
		d = date.split("-");
	}
	let formatDate = "";
	const mmddyyyy = 1,
		ddmmyyyy = 2,
		yyyymmdd = 3;
	if (type === mmddyyyy) {
		formatDate = d[1] + "/" + d[2] + "/" + d[0];
	} else if (type === ddmmyyyy) {
		formatDate = d[2] + "/" + d[1] + "/" + d[0];
	} else if (type === yyyymmdd) {
		formatDate = date;
	}
	return formatDate;
}
